<template>
    <div :class="options.type + '-error-container'">
        <v-alert type="warning" closable v-if="$nuxt.isOffline">Check your connection, you are offline.</v-alert>
        <div v-if="options.type == 'sidebar'">
            <v-alert :key="'error_' + errorIndex" type="error" closable v-for="(error, errorIndex) in store.sidebarErrors">{{ error.message }}</v-alert>
        </div>
        <div v-if="options.type == 'global'">
            <v-alert :key="'error_' + errorIndex" type="error" closable v-for="(error, errorIndex) in store.globalErrors">{{ error.message }}</v-alert>
        </div>
        <div v-if="options.type == 'all'">
            <v-alert :key="'error_' + errorIndex" type="error" closable v-for="(error, errorIndex) in store.globalErrors.concat(
                store.sidebarErrors
            )">{{ error.message }}</v-alert>
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store/index";

export default {
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    computed: {
        store() {
            return useStore();
        },
    },
};
</script>

<style scoped lang="scss">
.all-error-container,
.global-error-container {
    position: fixed;
    left: 48px;
    right: 48px;
    bottom: 65px;
    z-index: 1010;
}

:deep(.v-alert) {
    .v-alert__prepend {
        align-self: center !important;
    }

    .v-alert__close {
        .v-btn--icon.v-btn--density-default {
            /* reduce height to avoid forcing height to parent: 60px - 2x16px paddings */
            height: 28px;

            .mdi-close::before {
                color: white !important;
            }
        }
    }
}


/* mobile view */
@media (max-width: 820px) {
    .global-error-container {
        left: 16px;
        right: 16px;
        bottom: 65px;
    }
}
</style>
